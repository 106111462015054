import React, { createContext, useState, useRef } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactTypeformEmbed } from "react-typeform-embed";

import paperImage from "./paper.png";
import DownloadOnAppStore from "../assets/apps/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import TwitterLogo from "../assets/social/Twitter_Logo_Blue.svg";
import videoWebM from "../assets/video/one_phone_mockup_video_tighter-deadline-best-1120w.webm";
import videoMp4 from "../assets/video/one_phone_mockup_video_tighter-preset-veryslow-1120w.mp4";
import videoOnboarding2Mp4 from "../assets/video/onboarding/onboarding_2_freehand_preview_draw.mp4";
import videoOnboarding2WebM from "../assets/video/onboarding/onboarding_2_freehand_preview_draw.webm";
import videoOnboarding3Mp4 from "../assets/video/onboarding/onboarding_3_freehand_stabilized.mp4";
import videoOnboarding3WebM from "../assets/video/onboarding/onboarding_3_freehand_stabilized.webm";
import videoWebScreenMp4 from "../assets/video/two_device_cut_cropped_web-preset-veryslow-crf28-752w.mp4";
import videoWebScreenWebM from "../assets/video/two_device_cut_cropped_web-deadline-best-752w.webm";
import videoZoomMp4 from "../assets/video/zoom_cropped-preset-veryslow-crf30-1020w.mp4";
import videoZoomWebM from "../assets/video/zoom_cropped-deadline-best-crf40-1020w.webm";
import { Button, RoughNotationWrapper, Paragraph } from "../components/atoms";
import ContactUs from "../components/ContactUs";
import Header from "../components/Header";
import Layout, { mq } from "../components/layout";
import {
  Section,
  SectionHeading,
  SectionWrapper,
} from "../components/molecules";
import Testimonials from "../components/Testimonials";
import * as colors from "../utils/colors";

const LeftColumn = styled.div`
  max-width: 40rem;
  flex-grow: 1;
  padding: 0.5rem;
  align-self: center;
  ${mq[1]} {
    padding: 1rem;
  }
  ${mq[2]} {
    padding: 2rem 0 2rem 2rem;
  }
  ${mq[3]} {
    padding: 2rem 0 2rem 4rem;
  }
`;

const Video = ({ width, height, mp4, webm, ...rest }) => (
  <video
    autoPlay
    muted
    playsInline
    controls={false}
    loop
    width={width}
    height={height}
    css={css`
      max-width: 100%;
      align-self: center;
      border-radius: 1rem;
    `}
    {...rest}
  >
    {webm && <source src={webm} type="video/webm" />}
    {mp4 && <source src={mp4} type="video/mp4" />}
  </video>
);

const Form = () => {
  const typeformRef = useRef();

  const typeformUrl = encodeURI(
    `https://form.typeform.com/to/oh6zaOWD?source=www.grapic.co`
  );

  // const appStoreUrl = encodeURI(`https://apps.apple.com/app/id1515109130`);
  const appStoreUrl = encodeURI(`https://link.grapic.co/app-store-www`);

  return (
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
      `}
    >
      <div
        css={css`
          min-height: 4rem;
          ${mq[1]} {
            min-height: 5rem;
          }
        `}
      >
        <a
          href={appStoreUrl}
          target="_blank"
          rel="noopener noreferrer"
          css={css`
            display: flex;
            margin-right: 1rem;
          `}
        >
          <DownloadOnAppStore
            title="Download on the App Store"
            css={css`
              height: 52px;
              width: 154px;
            `}
          />
        </a>
      </div>
      <ReactTypeformEmbed
        key={typeformUrl}
        url={typeformUrl}
        popup
        mode="drawer_right"
        ref={typeformRef}
        style={{ zIndex: -1 }}
      />
      <Button
        primary
        black
        inverted
        noHover
        onClick={() => typeformRef.current.typeform.open()}
        css={css`
          align-self: start;
        `}
      >
        Android waitlist
      </Button>
      <a href="https://www.producthunt.com/posts/grapic?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-grapic">
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=321438&theme=light"
          alt="Grapic - Use your real whiteboard and notebook in hybrid meetings | Product Hunt"
          style={{ width: "250px", height: "54px" }}
          width="250"
          height="54"
        />
      </a>
    </div>
  );
};

const FirstRow = () => (
  <SectionWrapper>
    <Section
      css={css`
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      `}
    >
      <LeftColumn>
        <h2
          css={css`
            font-size: 3rem;
          `}
        >
          Have your remote meeting and whiteboard too
        </h2>
        <Paragraph
          css={css`
            margin-bottom: 2rem;
            max-width: 30rem; // to make rough notation on own row
          `}
        >
          <span>
            {`Collaborate on real whiteboards and notebooks in remote and `}
          </span>
          <RoughNotationWrapper
            type="highlight"
            show
            animationDelay={800}
            color={colors.GRAPIC_YELLOW}
            multiline
            css={css`
              font-size: 1.1rem;
              font-weight: 300;
            `}
          >
            {`hybrid meetings`}
          </RoughNotationWrapper>
          <span>{`.`}</span>
        </Paragraph>
        <div
          css={css`
            display: flex;
          `}
        >
          <div
            css={css`
              border-left: 6px solid ${colors.GRAPIC_TURQUOISE};
              height: 52px;
              margin-right: 1rem;
              border-radius: 10rem;

              display: none;
              ${mq[0]} {
                display: block;
              }
            `}
          ></div>
          <Form />
        </div>
      </LeftColumn>
      <div
        css={css`
          display: flex;
          justify-content: center;
        `}
      >
        <Video
          width={1091 / 1.6}
          // height={820 / 1.6}
          mp4={videoMp4}
          webm={videoWebM}
          css={css`
            ${mq[2]} {
              min-height: ${820 / 1.6}px;
            }
          `}
        />
      </div>
    </Section>
    <div
      css={css`
        height: 41px;
        background-color: ${colors.LIGHT_BACKGROUND};
        background-image: url(${paperImage});
        background-repeat: repeat-x;
      `}
    ></div>
  </SectionWrapper>
);

const ZoomRow = () => (
  <SectionWrapper>
    <Section>
      <LeftColumn>
        <SectionHeading>Share directly into Zoom or any browser</SectionHeading>
        <Paragraph>Just like screen sharing the real world.</Paragraph>
      </LeftColumn>
      <div
        css={css`
          display: flex;
          justify-content: center;
          margin: auto;
        `}
      >
        <Video
          width={500}
          mp4={videoZoomMp4}
          webm={videoZoomWebM}
          css={css`
            padding: 0 0.5rem;
            ${mq[2]} {
              min-height: ${450}px;
            }
          `}
        />
      </div>
    </Section>
  </SectionWrapper>
);

const FigJamRow = () => (
  <SectionWrapper>
    <Section
      css={css`
        ${mq[2]} {
          flex-direction: row-reverse;
        }
      `}
    >
      <LeftColumn>
        <SectionHeading>
          Freehand sketch right in FigJam by Figma
        </SectionHeading>
        <Paragraph>
          With the{" "}
          <a
            href="https://www.figma.com/community/widget/1038086438446539988"
            target="_blank"
            rel="noopener noreferrer"
            css={css`
              color: unset;
            `}
          >
            Grapic for FigJam
          </a>{" "}
          widget you it is super easy to bring your whiteboard or notebook into
          FigJam. Take a snapshots and have it automatically appear on your
          FigJam file and continue to work from there.
        </Paragraph>
      </LeftColumn>
      <div
        css={css`
          display: flex;
          flex-grow: 1;
          justify-content: center;
        `}
      >
        <StaticImage
          src="../assets/figjam/grapic-for-figjam-5.png"
          alt="Grapic for FigJam"
          width={540}
          formats={["auto", "webp", "avif"]}
          placeholder="tracedSVG"
        />
      </div>
    </Section>
  </SectionWrapper>
);

const SecondRow = () => (
  <SectionWrapper>
    <Section>
      <LeftColumn>
        <SectionHeading>All you need is your phone</SectionHeading>
        <Paragraph>
          Use the Grapic mobile app to share a video stream of your notebook or
          whiteboard to your remote colleagues. No need for a camera tripod or
          expensive equipment - our tech keeps things steady and focused
          instead.
        </Paragraph>
      </LeftColumn>
      <div
        css={css`
          display: flex;
          flex-grow: 1;
          justify-content: center;
        `}
      >
        <StaticImage
          src="../assets/illustrations/Stream_analog_test_transparent.png"
          alt="Pen and paper"
          width={540}
          formats={["auto", "webp", "avif"]}
          placeholder="tracedSVG"
        />
      </div>
    </Section>
  </SectionWrapper>
);

const WorkStepText = styled.div`
  margin-bottom: 4rem;
`;

const HowItWorksRow = () => (
  <SectionWrapper
    css={css`
      background: ${colors.GRAPIC_WHITE} !important;
    `}
  >
    <Section>
      <div
        css={css`
          padding: 0.5rem;

          ${mq[1]} {
            padding: 1rem;
          }
          ${mq[2]} {
            padding: 2rem;
          }
          ${mq[3]} {
            padding: 2rem 4rem;
          }
          display: flex;
          flex-direction: column;
          width: 100%;
        `}
      >
        <SectionHeading
          css={css`
            text-align: center;
          `}
        >
          How it works
        </SectionHeading>
        <div
          css={css`
            max-width: 47rem;
            margin: auto;
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin-bottom: 4rem;
              ${mq[2]} {
                margin: 3rem 0;
                flex-direction: row;
              }
            `}
          >
            <div>
              <h3>Draw when inspiration hits</h3>
              <WorkStepText>
                <Paragraph>
                  Scribble directly in your favourite notebook or on the back of
                  a napkin. Use the Grapic mobile app to share your process in
                  real-time, with minimal setup. Just select the area you want
                  to share, and start drawing.
                </Paragraph>
              </WorkStepText>
            </div>
            <Video
              width={350}
              // height={350}
              mp4={videoOnboarding2Mp4}
              webm={videoOnboarding2WebM}
              css={css`
                margin: 0 2rem;
                border: 1rem solid ${colors.LIGHT_BACKGROUND};
                object-fit: cover;
              `}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin-bottom: 4rem;
              ${mq[2]} {
                margin: 3rem 0;
                flex-direction: row-reverse;
              }
            `}
          >
            <div>
              <h3>Keep technology out of the way</h3>
              <WorkStepText>
                <Paragraph>
                  Grapic uses fancy augmented reality and image processing so
                  you can hold your phone where you like, and not think about
                  keeping it steady. Put your phone down when you’re not
                  drawing, and pick it up again to continue.
                </Paragraph>
              </WorkStepText>
            </div>
            <Video
              width={350}
              // height={350}
              mp4={videoOnboarding3Mp4}
              webm={videoOnboarding3WebM}
              css={css`
                margin: 0 2rem;
                border: 1rem solid ${colors.LIGHT_BACKGROUND};
                object-fit: cover;
              `}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              ${mq[2]} {
                margin: 3rem 0;
                text-align: center;
              }
            `}
          >
            <div>
              <h3>Share your page with colleagues</h3>
              <WorkStepText>
                <Paragraph>
                  Invite your colleagues to view your paper in real-time by
                  sharing a link to the session. When inspiration hits, they can
                  join in and start drawing too.
                </Paragraph>
              </WorkStepText>
            </div>
            <Video
              width={47 * 16}
              // height={470}
              mp4={videoWebScreenMp4}
              webm={videoWebScreenWebM}
            />
          </div>
        </div>
      </div>
    </Section>
  </SectionWrapper>
);

const thirdRowLink = css`
  color: unset;
  margin: 0.5rem 0.5rem 0.5rem 0;
`;

const ThirdRow = ({ twitterHandle }) => (
  <SectionWrapper
    css={css`
      background: ${colors.LIGHT_BACKGROUND} !important;
      // background: ${colors.GRAPIC_YELLOW} !important;
      // background: linear-gradient(
      //   270deg,
      //   ${colors.GRAPIC_YELLOW} 0%,
      //   ${colors.GRAPIC_WHITE} 75%
      // ) !important;
    `}
  >
    <Section
      css={css`
        padding-bottom: 0 !important;
      `}
    >
      <LeftColumn>
        <SectionHeading>Remember hassle free collaboration?</SectionHeading>
        <p
          css={css`
            font-weight: 300;
            margin-bottom: 2rem;
          `}
        >
          Bring back creativity to remote work. Collaborate like it’s 1999, but
          remote.
        </p>
        <Form />
        <p
          css={css`
            margin-top: 2rem;
            font-weight: 300;
          `}
        >
          {`Just want to talk? `}
          <ContactUs />
        </p>
        <p
          css={css`
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-weight: 300;
          `}
        >
          <a
            href={`https://twitter.com/${twitterHandle}`}
            target="_blank"
            rel="noopener noreferrer"
            css={css`
              margin-right: 1rem;
              height: 3rem;
            `}
          >
            <TwitterLogo
              title={`@${twitterHandle} at Twitter`}
              css={css`
                width: 3rem;
              `}
            />
          </a>
          <Link
            to="/about/"
            css={css`
              ${thirdRowLink}
            `}
          >
            About us
          </Link>
          <Link
            to="/how-to/"
            css={css`
              ${thirdRowLink}
            `}
          >
            How to
          </Link>
          <Link
            to="/dev-notes/"
            css={css`
              ${thirdRowLink}
            `}
          >
            Dev notes
          </Link>
          <Link
            to="/faq/"
            css={css`
              ${thirdRowLink}
            `}
          >
            FAQ
          </Link>
          <Link
            to="/terms/"
            css={css`
              ${thirdRowLink}
            `}
          >
            Terms
          </Link>
          <Link
            to="/privacy/"
            css={css`
              ${thirdRowLink}
            `}
          >
            Privacy
          </Link>
        </p>
      </LeftColumn>
      <div
        css={css`
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
        `}
      >
        <StaticImage
          src="../assets/illustrations/Rocket_Pen_White_Smoke.png"
          alt="Rocket pen"
          width={540}
          formats={["auto", "webp", "avif"]}
          placeholder="tracedSVG"
        />
      </div>
    </Section>
  </SectionWrapper>
);

// const Footer = ({ siteMetadata }) => (
//   <HeaderAndFooter
//     css={{
//       fontSize: "0.8rem",
//       flexDirection: "column",
//       [mq[0]]: {
//         flexDirection: "row",
//       },
//     }}
//   >
//     <p css={{ margin: "1rem" }}>
//       <a
//         href={`mailto:${siteMetadata.email}`}
//         target="_blank"
//         rel="noopener noreferrer"
//         css={{ color: "unset" }}
//       >
//         {siteMetadata.email}
//       </a>
//     </p>
//     <p css={{ margin: "1rem" }}>{`© 2020 ${siteMetadata.company}`}</p>
//   </HeaderAndFooter>
// );

const EmailContext = createContext({
  email: "",
  setEmail: () => {},
  isEmailValid: undefined,
  setIsEmailValid: () => {},
});
// const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const IndexPage = () => {
  const { site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            twitterHandle
          }
        }
      }
    `
  );

  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(undefined);

  return (
    <Layout includeStructuredOrg includeStructuredApp>
      <EmailContext.Provider
        value={{
          email: email,
          setEmail: setEmail,
          isEmailValid: isEmailValid,
          setIsEmailValid: setIsEmailValid,
        }}
      >
        <Header />
        <FirstRow />
        <ZoomRow />
        <FigJamRow />
        <Testimonials />
        <SecondRow />
        <HowItWorksRow />
        <ThirdRow twitterHandle={site.siteMetadata.twitterHandle} />
        {/* <Footer siteMetadata={site.siteMetadata} /> */}
      </EmailContext.Provider>
    </Layout>
  );
};

export default IndexPage;
